import { createGlobalStyle } from 'styled-components'
import { PancakeTheme } from '@pancakeswap/uikit'

declare module 'styled-components' {
  /* eslint-disable @typescript-eslint/no-empty-interface */
  export interface DefaultTheme extends PancakeTheme {}
}

const GlobalStyle = createGlobalStyle`
  * {
    font-family: 'Kanit', sans-serif;
  }
  body {
    background: ${({ theme }) => theme.colors.gradients.bubblegum};
    /* background: linear-gradient(90deg, #FFF0CA 0%, #E0D3EA 31%, #CBBFFF 53%, #DDD6E9 81%, #F6FFB2 100%); */
    img {
      height: auto;
      max-width: 100%;
    }
  }
`

export default GlobalStyle
